import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    tabs: {
      minHeight: theme.spacing(3.75),
      backgroundColor: theme.palette.background.paper,

      "& > .MuiTabScrollButton-root.Mui-disabled": {
        display: "none",
      },
    },
    tab: {
      minHeight: theme.spacing(3.75),
      padding: theme.spacing(0.5, 2),
      minWidth: "auto",
      fontSize: theme.typography.caption.fontSize,
      textTransform: "none",

      "&.Mui-selected": {
        backgroundColor: theme.palette.background.paper,
      },
    },
    tabContainer: {
      backgroundColor: theme.palette.background[800],
      height: "100%",
      overflow: "auto",
    },
  }),
  { name: "Tabs" }
);

export default function SimpleTabs({ tabs, selectedTab, children }) {
  const styles = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (selectedTab) {
      setSelectedIndex(selectedTab);
    }
  }, [selectedTab]);

  const handleChange = (newValue) => {
    setSelectedIndex(newValue);
  };

  return (
    <div className={styles.root}>
      <Tabs
        value={selectedIndex}
        onChange={handleChange}
        variant="scrollable"
        className={styles.tabs}
        indicatorColor="primary"
      >
        {tabs.map((tab, idx) => (
          <Tab
            key={tab.id}
            selected={selectedIndex === idx}
            className={styles.tab}
            {...tab}
            onClick={() => handleChange(idx)}
          />
        ))}
      </Tabs>

      <div className={styles.tabContainer}>{children({ selectedIndex })}</div>
    </div>
  );
}
