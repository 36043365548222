import React, { useState } from "react";
import { Box, makeStyles, TextField } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    maxWidth: 160,
  },
  span: {
    margin: "0 .2rem",
  },
}));

const Timecode = (props) => {
  const { value = {}, withUTC, disabled } = props;
  const {
    hours = "00",
    minutes = "00",
    seconds = "00",
    frames = "00",
    utc = false,
    dropFrame = false,
  } = value || {};
  const [fieldHours, setFieldHours] = useState(hours || "00");
  const [fieldMinutes, setFieldMinutes] = useState(minutes || "00");
  const [fieldSeconds, setFieldSeconds] = useState(seconds || "00");
  const [fieldFrames, setFieldFrames] = useState(frames || "00");
  const styles = useStyles();

  const handleChange = (e) => {
    const val = e.target.value;
    const valToSave = val < 10 ? `0${val}` : val;

    switch (e.target.name) {
      case "hours":
        setFieldHours(valToSave);
        break;

      case "minutes":
        setFieldMinutes(valToSave);
        break;

      case "seconds":
        setFieldSeconds(valToSave);
        break;

      case "frames":
        setFieldFrames(valToSave);
        break;

      default:
        break;
    }
  };

  if (disabled) {
    return (
      <Box width="100%">
        <TextField fullWidth disabled type="string" value={value} />
      </Box>
    );
  }

  return (
    <div className={styles.container}>
      <TextField
        type="number"
        name="hours"
        value={fieldHours}
        onChange={handleChange}
      />
      <span className={styles.span}>:</span>
      <TextField
        type="number"
        name="minutes"
        value={fieldMinutes}
        onChange={handleChange}
      />
      <span className={styles.span}>:</span>
      <TextField
        type="number"
        name="seconds"
        value={fieldSeconds}
        onChange={handleChange}
      />
      <span className={styles.span}>{dropFrame ? ";" : ":"}</span>
      <TextField
        type="number"
        name="frames"
        value={fieldFrames}
        onChange={handleChange}
      />
      {withUTC && utc && <span className={styles.span}>Z</span>}
    </div>
  );
};

export default Timecode;
