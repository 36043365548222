import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Collapse } from "@material-ui/core";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.type === "dark" ? theme.palette.common.white : "#000",
    padding: theme.spacing(1),
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  body: {
    padding: theme.spacing(0.8),
    fontSize: theme.typography.pxToRem(14),
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
  container: {},
}));

const useRowStyles = makeStyles(() => ({
  row: {
    // cursor: "pointer",
  },
  rowExpandable: {
    cursor: "pointer",
  },
  expandable: {
    padding: 0,
  },
}));

const Row = (props) => {
  const { row, columns, renderExpandable } = props;
  const [open, setOpen] = React.useState(false);
  const styles = useRowStyles();

  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        className={[styles.row, renderExpandable && styles.rowExpandable]}
        onClick={() => setOpen(!open)}
      >
        {columns.map((col, idx) => (
          <React.Fragment key={idx}>
            {col.Cell ? (
              col.Cell(row)
            ) : (
              <StyledTableCell>{row[col.accessor]}</StyledTableCell>
            )}
          </React.Fragment>
        ))}
      </TableRow>
      <TableRow key={`${row.id}-expanded`}>
        <TableCell className={styles.expandable} colSpan={columns.length}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {renderExpandable ? renderExpandable(row) : null}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default function CustomizedTables(props) {
  const { data, columns, renderExpandable } = props;
  const classes = useStyles();

  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className={classes.table}
        aria-label="customized table"
        size="small"
      >
        <TableHead>
          <TableRow>
            {columns.map((col, idx) => (
              <StyledTableCell key={idx}>{col.Header}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, idx) => (
            <Row
              key={idx}
              row={row}
              columns={columns}
              renderExpandable={renderExpandable}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
