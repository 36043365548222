import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    '& > *': {
      margin: '0 .3em',
    },
  },
  hiddenIcon: {
    visibility: 'hidden',
  },
}));

const ColumnHeader = (props) => {
  const { column, sortBy, sortDir, onSortFilter } = props;
  const { accessor, header, sortable = true } = column;
  const styles = useStyles();

  const handleClick = () => {
    let direction = "";
    let columnId = accessor;

    if (sortBy === accessor) {
      if (sortDir === "") {
        direction = "asc";
      } else if (sortDir === "asc") {
        direction = "desc";
      } else if (sortDir === "desc") {
        columnId = "";
      }
    } else {
      direction = "asc";
    }

    onSortFilter(columnId, direction);
  };

  const renderHeaderIcon = () => {
    if (sortBy !== accessor || sortDir === '') {
      return <ArrowUpwardIcon className={styles.hiddenIcon} fontSize="small" />;
    }

    switch (sortDir) {
      default:
        return null;

      case 'asc':
        return <ArrowUpwardIcon fontSize="small" />;

      case 'desc':
        return <ArrowDownwardIcon fontSize="small" />;
    }
  };

  if (!sortable) {
    return header;
  }

  return (
    <div className={styles.container} onClick={handleClick}>
      {header}
      {renderHeaderIcon()}
    </div>
  );
};

export default ColumnHeader;
