import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles(() => ({
  popover: {},
  paper: {
    maxWidth: "auto",
    maxHeight: "auto",
  },
}));

const StickyItem = (props) => {
  const { icon, children } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const styles = useStyles();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsModalOpen(false);
  };

  return (
    <React.Fragment>
      <button onClick={handleOpen}>
        <div>
          {icon}
        </div>
      </button>
      <Popover
        classes={{
          root: styles.popover,
          paper: styles.paper,
        }}
        open={isModalOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        {children}
      </Popover>
    </React.Fragment>
  );
};

export default StickyItem;
