const common = {
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "html, body, #root": {
          height: "100%",
        },
        "html *": {
          "-webkit-font-smoothing": "antialiased",
          "-moz-osx-font-smoothing": "grayscale",
        },
        a: {
          color: "inherit",
        },
        "*::-webkit-scrollbar": {
          width: 8,
          height: 8,
        },

        "*::-webkit-scrollbar-track": {
          backgroundColor: "transparent",
        },

        "*::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(124, 133, 141, .5)",
          borderRadius: 3,
        },

        "*::selection": {
          color: "#fff",
          background: "#f4670a",
        },
      },
    },
    MuiAvatar: {
      root: {
        textTransform: "uppercase",
      },
    },
  },
  typography: {
    // default fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

    // default fontSize: 14
    fontSize: 14,

    // default htmlFontSize: 16
    htmlFontSize: 16,

    body1: {
      fontSize: 14,
    },
  },

  // default spacing 8
  spacing: 8,

  // custom variables
  sidebar: {
    widthExpanded: 208,
    widthCollapsed: 54,
  },
  panel: {
    headerHeight: 40,
  },
  pane: {
    minHeight: 10,
  },
  handle: {
    size: 4,
  },
  scrollbar: {
    size: 8,
  },
  tableCell: {
    minWidth: 50,
    height: 53,
    heightWithMargin: 63,
    heightExpanded: 535,
  },
};

export default common;
