import { createTheme } from "@material-ui/core/styles";
import common from "./common";

export default createTheme({
  ...common,
  palette: {
    primary: {
      main: "#f4670a",
    },
    secondary: {
      main: "#1976d2",
    },
    background: {
      // obsolete
      100: "#fff",
      200: "#fff",
      300: "#fff",
      800: "#f6f6f6",

      // for typescript support
      v100: "#fff",
      v200: "#fff",
      v300: "#fff",
      v800: "#f6f6f6",

      default: "#eee",
      paper: "#fff",
    },
    sidebar: {
      default: "#26292c",
    },
  },
});
