import React, { useState } from "react";
import { Checkbox as MaterialCheckbox } from "@material-ui/core";

const Checkbox = (props) => {
  const {
    name,
    checked,
    disabled,
    onChange,
    onBlur = () => {},
  } = props;
  const [fieldValue, setFieldValue] = useState(checked || false);

  const handleChange = (e) => {
    setFieldValue(!fieldValue);

    if (onChange) {
      onChange(e.target.name, !fieldValue);
    }
  };

  return (
    <MaterialCheckbox
      color="primary"
      name={name}
      disabled={disabled}
      checked={fieldValue}
      onBlur={onBlur}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'secondary checkbox' }}
    />
  );
};

export default Checkbox;
