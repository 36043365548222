const VERSION = "0.0.1";
const storage = window.localStorage;
let storageKey = null;

const checkStorageKey = () => {
  if (!storageKey) {
    console.warn(
      "No localstorage key defined for the project. Run initStorage() before project starts"
    );
    return false;
  }

  return true;
};

export const initStorage = ({ key }) => {
  storageKey = key;
};

export const load = (key) => {
  if (!checkStorageKey()) return;

  return storage.getItem(`${storageKey}-${key}`);
};
export const save = (key, value) => {
  if (!checkStorageKey()) return;

  storage.setItem(`${storageKey}-${key}`, value);
};

export const checkVersion = () => {
  if (!checkStorageKey()) return;

  const version = storage.getItem(`${storageKey}-version`);

  if (version !== VERSION) {
    storage.removeItem(`${storageKey}-settings`);
  }

  storage.setItem(`${storageKey}-version`, VERSION);
};

export const loadSettings = () => {
  if (!checkStorageKey()) return;

  return JSON.parse(storage.getItem(`${storageKey}-settings`));
};

export const saveSettings = (value) => {
  if (!checkStorageKey()) return;

  storage.setItem(`${storageKey}-settings`, JSON.stringify(value));
};

export const loadWorkspace = ({ id, version }) => {
  const settings = loadSettings();
  if (!settings) return null;

  const workspace = settings[id];

  if (!workspace) return null;
  if (workspace.version !== version) return null;

  return workspace;
};

export const saveWorkspace = (workspace) => {
  const settings = loadSettings() || {};

  saveSettings({
    ...settings,
    [workspace.id]: workspace,
  });
};
