import React from "react";
import {
  Typography,
  Box,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    message: {
      textAlign: "center",
    },
    text: {
      fontSize: theme.spacing(1.5),
      textTransform: "uppercase",
      opacity: 0.5,
      userSelect: "none",
    },
  }),
  { name: "Message" }
);

export default function Message({ preload, children }) {
  const styles = useStyles();

  return (
    <Box className={styles.root}>
      <Box className={styles.message}>
        {preload && <CircularProgress />}
        <Typography className={styles.text}>{children}</Typography>
      </Box>
    </Box>
  );
}
