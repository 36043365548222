import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      "& img": {
        maxWidth: "100%",
        display: "block",
      },
    },
  }),
  { name: "Logo" }
);

const variants = {
  symbol: "/img/logo.svg",
  full: "/img/logo-full.svg",
  inline: "/img/logo-inline.svg",
};

export default function Logo({ variant }) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <img alt="logo" src={variants[variant] || variants[0]} />
    </div>
  );
}

Logo.defaultProps = {
  variant: "full",
};
