import React from "react";
import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > *': {
      marginRight: 5,
    }
  }
}));

const TablePagination = (props) => {
  const { itemsCount, pagination, handlePrevPage, handleNextPage } = props;
  const { currentPage, hasPrevPage, hasNextPage } = pagination;
  const classes = useStyles();

  if (currentPage === 0 || (currentPage === 1 && !itemsCount)) {
    return null;
  }

  return (
    <div className={classes.container}>
      <p>{`Page ${currentPage}`}</p>
      <div>
        <IconButton
          disabled={!hasPrevPage}
          onClick={handlePrevPage}
        >
          <NavigateBeforeIcon fontSize="small" />
        </IconButton>
        <IconButton
          disabled={!hasNextPage}
          onClick={handleNextPage}
        >
          <NavigateNextIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default TablePagination;
