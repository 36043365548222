import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStylesOriginal = makeStyles(
  (theme) => ({
    root: {
      position: "fixed",
      right: 0,
      top: "100px",
      zIndex: 1000,
      transform: "translate3d(60%,0,0)",
      transition: "transform 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

      "&:hover, &.active": {
        transform: "translate3d(0,0,0)",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(1.5),

      "& button": {
        background: "rgba(0, 0, 0, 0.3)",
        borderRadius: theme.spacing(0.5),
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        textAlign: "center",
        color: "#fff",
        border: "none",
        cursor: "pointer",
        padding: theme.spacing(1),
        margin: theme.spacing(0.5, 0),

        "& > div": {
          fontSize: 1,
        },
      },
    },
  }),
  { name: "StickyMenu" }
);

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "fixed",
      right: 0,
      bottom: 0,
      zIndex: 1000,
      transform: "translate3d(0,94%,0)",
      transition: "transform 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",

      "&:hover, &.active": {
        transform: "translate3d(0,0,0)",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      marginLeft: theme.spacing(1.5),

      "& button": {
        background: "rgba(0, 0, 0, 0.3)",
        borderRadius: theme.spacing(0.5),
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        textAlign: "center",
        color: "#fff",
        border: "none",
        cursor: "pointer",
        padding: theme.spacing(1),
        margin: theme.spacing(0.5, 0),

        "& > div": {
          fontSize: 1,
        },
      },
    },
  }),
  { name: "StickyMenu" }
);

const StickyMenu = (props) => {
  const { children } = props;
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.container}>{children}</div>
    </div>
  );
};

export default StickyMenu;
