import React from "react";
import { NavLink } from "react-router-dom";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

export default function MenuItem({ title, to, icon, external, style }) {
  const Icon = icon;

  return (
    <ListItem
      button
      component={NavLink}
      title={title}
      to={to}
      style={style}
      exact
    >
      <ListItemIcon>{icon && <Icon />}</ListItemIcon>
      <ListItemText
        primary={title}
        primaryTypographyProps={{
          noWrap: true,
        }}
      />
      {external && <OpenInNewIcon fontSize="small" />}
    </ListItem>
  );
}
