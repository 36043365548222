import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1em .5em',
  },
  searchContainer: {
    borderBottom: '1px solid #f3f3f3',
    '&:hover, &:focus': {
      borderBottomColor: 'rgba(0,0,0,.5)',
    },
  },
  icon: {
    padding: '0 .3em',
  },
}));

const TableSearch = (props) => {
  const { search, onSearchFilter } = props;
  const [keyword, setKeyword] = useState('');
  const styles = useStyles();

  useEffect(() => {
    if (search !== keyword) {
      setKeyword(search);
    }
  }, [search]);

  const handleChange = (event) => {
    const { value } = event.target;
    setKeyword(value);
  };

  const handleClear = () => {
    onSearchFilter('');
  };

  const handleSearch = () => {
    onSearchFilter(keyword);
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <IconButton
          className={styles.icon}
          aria-label="search"
          onClick={handleSearch}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
        <InputBase
          placeholder="Search"
          onChange={handleChange}
          value={keyword}
        />
        <IconButton
          disabled={!keyword.length}
          onClick={handleClear}
          className={styles.icon}
          aria-label="clear"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    </div>
  );
};

export default TableSearch;
