import React from "react";
import DarkThemeIcon from "@material-ui/icons/Brightness4";
import LightThemeIcon from "@material-ui/icons/BrightnessHigh";
import { useTheme } from "@material-ui/core";

const StickyThemeToggle = (props) => {
  const { toggleTheme } = props;
  const theme = useTheme();

  return (
    <button onClick={toggleTheme} title="Switch theme (Beta)">
      {theme.palette.type === "dark" ? <DarkThemeIcon /> : <LightThemeIcon />}
    </button>
  );
};

export default StickyThemeToggle;
