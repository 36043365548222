import React from "react";
import { List as MuiList, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      padding: theme.spacing(1, 1),

      "& .MuiListItem-root": {
        padding: theme.spacing(0.75, 1),
        margin: theme.spacing(1, 0),
      },

      "& .MuiListItem-root:first-child": {
        marginTop: 0,
      },
      "& .MuiListItem-root:last-child": {
        marginBottom: 0,
      },

      "& .MuiButtonBase-root": {
        borderRadius: 3,
        "&:hover, &.expanded": {
          backgroundColor: "rgba(255,255,255,.1)",
        },
        "&.active": {
          backgroundColor: theme.palette.primary.main,
        },
      },
      "& .MuiListItemIcon-root": {
        color: "#fff",
        minWidth: theme.spacing(5),
      },
      "& .MuiAvatar-root": {
        width: theme.spacing(3.75),
        height: theme.spacing(3.75),
        marginLeft: theme.spacing(-0.5),
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 700,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
      },
      "& .MuiSvgIcon": {
        "&-root": {
          fontSize: theme.spacing(2.5),
        },
        "&-fontSizeSmall": {
          fontSize: theme.spacing(1.75),
        },
      },
      "& .MuiListItemSecondaryAction-root": {
        fontSize: 1,
        right: 10,
      },
    },
  }),
  { name: "List" }
);

export default function List(props) {
  const styles = useStyles();
  const { children } = props;

  return (
    <MuiList {...props} className={styles.root}>
      {children}
    </MuiList>
  );
}
