import { createTheme } from "@material-ui/core/styles";
import common from "./common";

export default createTheme({
  ...common,
  palette: {
    type: "dark",
    text: {
      primary: "#e3e9f5",
    },
    primary: {
      main: "#f4670a",
    },
    secondary: {
      main: "#1976d2",
    },
    background: {
      // obsolete
      100: "#c5c9cd",
      200: "#62656e",
      300: "#4c4f56",
      800: "#26292c",

      // for typescript support
      v100: "#c5c9cd",
      v200: "#62656e",
      v300: "#4c4f56",
      v800: "#26292c",

      default: "#191b1d",
      paper: "#34383c",
    },
    sidebar: {
      default: "#26292c",
    },
  },
});
