import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function ListSubmenuItem({ children, title, icon, submenu }) {
  const [expanded, setExpanded] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (submenu && submenu.paths.includes(pathname)) {
      setExpanded(true);
    }
  }, [submenu, pathname]);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const Icon = icon;

  return (
    <React.Fragment>
      <ListItem
        button
        title={title}
        onClick={handleClick}
        className={expanded ? "expanded" : ""}
      >
        <ListItemIcon>{icon && <Icon />}</ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            noWrap: true,
          }}
        />
        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </React.Fragment>
  );
}
