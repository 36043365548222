import React from "react";
import { makeStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "./TablePagination";
import ColumnHeader from "./ColumnHeader";
import TableSearch from "./TableSearch";

const useStyles = makeStyles(
  (theme) => ({
    container: {},

    row: {
      "& th": {
        fontSize: theme.typography.caption.fontSize,
        fontWeight: "bold",
      },

      "& th, & td": {
        whiteSpace: "nowrap",
      },

      "& td": {
        "&.trim, &.trim > div": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },

      "&.MuiTableRow-hover": {
        cursor: "pointer",
      },

      "&.Mui-selected.MuiTableRow-hover": {
        background: theme.palette.primary.main,
        color: "#fff",
      },

      "&.Mui-selected .MuiTableCell-body, &.Mui-selected .MuiButtonBase-root": {
        color: "#fff",
      },
    },
  }),
  { name: "DynamicTable" }
);

export default function DynamicTable({
  height,
  columns,
  list,
  selected,
  search,
  sortBy,
  sortDir,
  onClick = () => {},
  pagination,
  noEntriesMessage,
  handlePrevPage,
  handleNextPage,
  onSearchFilter,
  onSortFilter,
}) {
  const styles = useStyles();

  return (
    <React.Fragment>
      {onSearchFilter && (
        <TableSearch search={search} onSearchFilter={onSearchFilter} />
      )}

      <TableContainer
        className={styles.container}
        style={{ height: height || "100%" }}
      >
        {columns && (
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow className={styles.row}>
                {columns.map((col, idx) => (
                  <TableCell
                    key={idx}
                    component="th"
                    className={col.width ? "trim" : ""}
                    align={col.align || "inherit"}
                    style={{ maxWidth: col.width || "none" }}
                  >
                    <ColumnHeader
                      column={col}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      onSortFilter={onSortFilter}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {list.length > 0 ? (
                <React.Fragment>
                  {list.map((row, rowIdx) => (
                    <TableRow
                      hover
                      key={rowIdx}
                      selected={rowIdx === selected}
                      onClick={() => onClick(rowIdx, row)}
                      className={styles.row}
                    >
                      {columns.map((col, colIdx) => (
                        <TableCell
                          key={colIdx}
                          scope="row"
                          align={col.align || "inherit"}
                          className={col.width ? "trim" : ""}
                          style={{ maxWidth: col.width || "none" }}
                        >
                          {col.render
                            ? col.render(row, rowIdx)
                            : row[col.accessor]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </React.Fragment>
              ) : (
                <TableRow className={styles.row}>
                  <TableCell colSpan={columns.length} scope="row">
                    {noEntriesMessage || "No entries"}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>

      {pagination && (
        <TablePagination
          itemsCount={list?.length}
          pagination={pagination}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      )}
    </React.Fragment>
  );
}
