import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {},

  item: {
    backgroundColor: theme.palette.background[800],
    borderRadius: 0,
    boxShadow: "none",
    "&.Mui-expanded": {
      margin: 0,
    },
  },

  itemContent: {
    "& > .MuiAccordionSummary-content": {
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

export const AccordionItem = ({ title, secondaryIcon, children }) => {
  const styles = useStyles();

  return (
    <Accordion square className={styles.item}>
      <AccordionSummary
        className={styles.itemContent}
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography>{title}</Typography>
        {secondaryIcon}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default function SimpleAccordion({ children }) {
  const styles = useStyles();

  return <div className={styles.root}>{children}</div>;
}
