import React, { useContext, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core";
import particles, { resize } from "./particles";

const useStyles = makeStyles(
  () => ({
    root: {
      height: "100%",
      position: "relative",

      "& canvas": {
        position: "absolute",
        top: 0,
        left: 0,
      },
    },
  }),
  { name: "ParticlesBackground" }
);

export default function ParticlesBackground({ width }) {
  const styles = useStyles();
  const containerEl = useRef(null);
  const canvasEl = useRef(null);
  const theme = useTheme();

  useEffect(() => {
    const onResize = (e) => resize(containerEl.current, canvasEl.current);

    particles(width, containerEl.current, canvasEl.current, {
      repaintColor: theme.palette.sidebar.default,
    });

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [width]);

  return (
    <div className={styles.root} ref={containerEl}>
      <canvas ref={canvasEl}></canvas>
    </div>
  );
}
